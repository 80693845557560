<template>
  <div class="box">
    <div class="container">
      <p class="title">{{this.$t('rules.title')}}</p>
      <div class="text_box">
        <div class="text">
          <p>{{ this.$t('rules.content') }}</p>
          <span>{{ this.$t('rules.title_a') }}</span>
          <hr />
          <p>{{ this.$t('rules.content_a') }}</p>
          <span>{{ this.$t('rules.title_b1') }}</span>
          <hr />
          <h3 style="margin-top: 30px">{{ this.$t('rules.title_b2') }}</h3>
          <p>{{ this.$t('rules.content_b') }}</p>
          <br />
          <h3>{{ this.$t('rules.title_c') }}</h3>
          <p>{{ this.$t('rules.content_c') }}</p>
          <span>{{ this.$t('rules.title_d') }}</span>
          <hr />
          <p>{{ this.$t('rules.content_d') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less">
.box {
  .container {
    margin-bottom: 100px;
    > p {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 30px;
      font-family: 'Meiryooo';
      line-height: 40px;
      color: #000000;
      opacity: 1;
      font-weight: 600;
      text-align: center;
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
    }
    .text_box {
    //   padding: 20px 12% 48px 12%;
      margin: 0 12%;
      background-color: #ffffff;
      text-align: left;
      .text {
        margin-top: 48px;
        // margin: 48px 18% 0 18%;
        span {
          font-size: 22px;
          font-family: 'Meiryooo';
          line-height: 22px;
          color: #000000;
          opacity: 1;
          margin-top: 20px;
        }
        p {
          font-size: 16px;
          font-family: 'Meiryooo';
          line-height: 26px;
          color: #000000;
          opacity: 1;
          margin-bottom: 30px;
          margin-top: 10px;
        }
        hr {
          border: lightgray 1px solid;
        }
      }
    }
  }
}
</style>